import { pf } from "../utilities/helpers";
import { DeliveryMethod } from "../context/ShoppingCartContext";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

interface Props {
  deliveryMethod: DeliveryMethod | null;
  setShowDeliveryModal: (val: boolean) => void;
  setModalDeliveryMethod: (val: DeliveryMethod | null) => void;
}

export default function CheckoutDeliveryMethodButton({
  deliveryMethod,
  setShowDeliveryModal,
  setModalDeliveryMethod,
}: Props) {
  const lang = usePageLanguage();
  return (
    <div className="col-xs-12 margin-bottom-10">
      <button
        className="btn btn-primary-outline text-left checkoutDeliveryButton shop-controlled-border-radius"
        style={{ width: "100%" }}
        onClick={() => {
          setShowDeliveryModal(true);
          setModalDeliveryMethod(deliveryMethod);
        }}
      >
        {deliveryMethod?.locale_translations[lang]?.name}
        <span className="pull-right">
          {" "}
          {pf(deliveryMethod!.deliveryMethodPrice.price)} &euro;
        </span>
      </button>
    </div>
  );
}
