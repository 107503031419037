import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage4Avaleht from "./pages/Webpage4Avaleht";
import Webpage5Tingimused from "./pages/Webpage5Tingimused";
import Webpage6Terms from "./pages/Webpage6Terms";
import Webpage9K__psised from "./pages/Webpage9K__psised";
import Webpage10Tooted from "./pages/Webpage10Tooted";
import Webpage11Toode from "./pages/Webpage11Toode";
import Webpage12Tellimus from "./pages/Webpage12Tellimus";
import Webpage16Products from "./pages/Webpage16Products";
import Webpage17Product from "./pages/Webpage17Product";
import Webpage18Checkout from "./pages/Webpage18Checkout";
import Webpage19Home from "./pages/Webpage19Home";
import Webpage20Cookie_policy from "./pages/Webpage20Cookie_policy";
import Webpage22Page_not_found from "./pages/Webpage22Page_not_found";

var baseURL = 'https://lilleorukeskus.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNTZiMmU2NjhmOWE0Nzc3NjQwMDVhZDBlYzRhYTY3ZDk2MTUxM2QxNDgzMDAyMWQwYmFmOGExMTRkNTU5Y2YyNzU3Y2I5OWEzMjAyMmI1ZmMiLCJpYXQiOjE3MjQ3Mzk3MjQuNzM4NzYzLCJuYmYiOjE3MjQ3Mzk3MjQuNzM4NzY4LCJleHAiOjE3MjQ4MjYxMjQuNzM1MTU0LCJzdWIiOiI3NSIsInNjb3BlcyI6W119.u7vJkBeUs34cjS1LG50rBuKcmItMBLHWoDHXCj84FAzPXaUip9AV3ch4Z_83LM0ry8BIW_dbqwH9Tc33VjR94itaKKyhMWRMp0K01BUo5jmiclWPoWRS0EWoetCLofyeErtwsAWa252u6FAsJTmQG2jrnbK5PDq7-0CD6ENOMBGxL-Zseiq0SwTYg35-fQ3AdEqbNaeaFJVS31973rM5DyMIrXTMYwUx67Q0LbwJfltIgJkgo_mkFudJkdK1rPQsxLwyPwnfl-IADCyc56FaLfAI2zCnzyQBAtJ7x_i4QHz7E6fKtlV-AnCxOf3nX_gJeFfnAvEgdF9Fl5sO2IfXBs710OOXKH8NyNtVI0587i0_XuBOxGwJ7XRVRA_nsUOFcK7peHRWl8fzMEYSu2VsWOQ_vdwwvTFhpVaz1PMZiToQsiPZ_XXr-M4aMrWkO1l8FWPdxEsDFWyh0fNxXidp6CJKSLg5mVYImQTr-kCy1B-syGe7iwCTal4OmbYaYRoEfs_x7yVBkp22Dfla5_UNkGc0Xfvp3cai8P5CgHwqXihyOVotk34Ntz_5sDT53LabNEqnSE3gRRmkN2mKhKYs5NrQ7u2_ypwspvE7vFTf9uvxOugGMK-UU8WeYe4vWX0a_twImU0fjROxRGJ1Fuz922fh7vvjPstfYgQV_Ns3dEY',
    'Access-api-key': "eyJpdiI6IkJLU25xaGJPYXlxN2czd1JMZnpXcEE9PSIsInZhbHVlIjoiOFBucCtjYmFWRGlNenlTaXlRUFliZz09IiwibWFjIjoiYjViNTEwMWUzMzMzMTFiOTE3NmNlNWM3MTk0M2Q4N2ZiMGM4NmNjZjM5MzgyMzExMWNkOGRjMjljMDdhY2NiZiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IkFYdmFYU0xYbXpCcVVDQm5UQVJqYnc9PSIsInZhbHVlIjoiTEZmVDdWd3JNQzBqUi8rdi8yalFmdz09IiwibWFjIjoiNTUwY2I3MjkxY2UxZDIyYzE2YWRmNjU5MTJiMDE2ZjY0OGRiNWQxOGY4YTU3Y2VlNjczNmU1MDNhYTc0ZDkzNyIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage4Avaleht />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage5Tingimused />} exact />;
                                                                    <Route path="/en/terms" element={<Webpage6Terms />} exact />;
                                                                    <Route path="/et/kupsised" element={<Webpage9K__psised />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage10Tooted />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage11Toode />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage12Tellimus />} exact />;
                                                                    <Route path="/en/products" element={<Webpage16Products />} exact />;
                                                                    <Route path="/en/product/:id" element={<Webpage17Product />} exact />;
                                                                    <Route path="/en/checkout" element={<Webpage18Checkout />} exact />;
                                                                    <Route path="/en/" element={<Webpage19Home />} exact />;
                                                                    <Route path="/en/cookiepolicy" element={<Webpage20Cookie_policy />} exact />;
                                                                    <Route path="/et/*" element={<Webpage22Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage22Page_not_found />} exact />;
                                                                
                                            <Route path="/toode/alladini-komplekt-4-klaasiga" element={<Redirect to="/toode/alladini-karahvin-kuldne-13l-4-joogiklaasi" />} exact />;
                                            <Route path="/tootekategooria/kingitused-lilleorust/" element={<Redirect to="/tooted?category=15" />} exact />;
                                            <Route path="/tootekategooria/kingitused-lilleorust/valitud-kingitused/" element={<Redirect to="/tooted?category=15" />} exact />;
                                            <Route path="/tootekategooria/jooga/" element={<Redirect to="/tooted?category=6" />} exact />;
                                            <Route path="/jooga/jooga-3/" element={<Redirect to="/tooted?category=24" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/jooga-suvajooga/" element={<Redirect to="/tooted?category=52" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/jooga-suvajooga/pildid/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/sisemine-jooga/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/raamatud/" element={<Redirect to="/tooted?category=1" />} exact />;
                                            <Route path="/tootekategooria/raamatud/ingliskeelsed-raamatud/" element={<Redirect to="/tooted?category=23" />} exact />;
                                            <Route path="/tootekategooria/teed/" element={<Redirect to="/tooted?category=2" />} exact />;
                                            <Route path="/tootekategooria/teed/lilleoru-teesegud/" element={<Redirect to="/tooted?category=60" />} exact />;
                                            <Route path="/tootekategooria/teed/teesegud/" element={<Redirect to="/tooted?category=38" />} exact />;
                                            <Route path="/tootekategooria/mesindus/" element={<Redirect to="/tooted?category=16" />} exact />;
                                            <Route path="/tootekategooria/mesindus/mesindus-2/" element={<Redirect to="/tooted?category=18" />} exact />;
                                            <Route path="/tootekategooria/mesindus/punkti-meed/" element={<Redirect to="/tooted?category=19" />} exact />;
                                            <Route path="/tootekategooria/mesindus/puidust-kinkekastid/" element={<Redirect to="/tooted?category=20" />} exact />;
                                            <Route path="/tootekategooria/mesindus/kinkekastid/" element={<Redirect to="/tooted?category=105" />} exact />;
                                            <Route path="/tootekategooria/hudrolaadid/" element={<Redirect to="/tooted?category=69" />} exact />;
                                            <Route path="/tootekategooria/aroomid-ja-viirukid/" element={<Redirect to="/tooted?category=9" />} exact />;
                                            <Route path="/tootekategooria/tervisetooted/" element={<Redirect to="/tooted?category=7" />} exact />;
                                            <Route path="/tootekategooria/looduskosmeetika/" element={<Redirect to="/tooted?category=5" />} exact />;
                                            <Route path="/tootekategooria/veenoud/" element={<Redirect to="/tooted?category=4" />} exact />;
                                            <Route path="/tootekategooria/veepudelid/" element={<Redirect to="/tooted?category=28" />} exact />;
                                            <Route path="/tootekategooria/keraamilised-tassid/" element={<Redirect to="/tooted?category=3" />} exact />;
                                            <Route path="/tootekategooria/ehted/" element={<Redirect to="/et/tooted?category=8" />} exact />;
                                            <Route path="/tootekategooria/ehted/puidust-ehted/" element={<Redirect to="/tooted?category=128" />} exact />;
                                            <Route path="/tootekategooria/ehted/malad/" element={<Redirect to="/tooted?category=48" />} exact />;
                                            <Route path="/tootekategooria/ehted/ehted-2/" element={<Redirect to="/tooted?category=47" />} exact />;
                                            <Route path="/tootekategooria/markmikud/" element={<Redirect to="/tooted?category=10" />} exact />;
                                            <Route path="/tootekategooria/markmikud/kristiina-pollu/" element={<Redirect to="/tooted?category=26" />} exact />;
                                            <Route path="/tootekategooria/riided/" element={<Redirect to="/tooted?category=14" />} exact />;
                                            <Route path="/tootekategooria/riided/ishwara-pildiga-tooted/" element={<Redirect to="/tooted?category=93" />} exact />;
                                            <Route path="/tootekategooria/riided/dakini/" element={<Redirect to="/tooted?category=14" />} exact />;
                                            <Route path="/tootekategooria/kunst/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/avatar/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/ahti-sepsivart/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kadri-kangilaski/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kaie-kal/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kaija-kesa/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/toomas-tonissoo/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/kauba-tagastamine/" element={<Redirect to="/tingimused" />} exact />;
                                            <Route path="/kontakt/" element={<Redirect to="/" />} exact />;
                                            <Route path="/minu-konto/" element={<Redirect to="/" />} exact />;
                                            <Route path="/ostutingimused/" element={<Redirect to="/tingimused" />} exact />;
                                            <Route path="/pood" element={<Redirect to="/tooted" />} exact />;
                                            <Route path="/tarnetingimused/" element={<Redirect to="/tingimused" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}