import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useEffect, useState } from "react";
import { useShoppingCart } from "./context/ShoppingCartContext";
import { pf } from "./utilities/helpers";
import { useAppSelector } from "../../store/hooks";
import { CartComponent as CartComponent_ } from "./interfaces/base";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
import React from "react";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);
export default function CartComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: CartComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const [mouseUp, setMouseUp] = useState<any>(null);
  const [keyDown, setKeyDown] = useState<any>(null);
  const typographies = useAppSelector((state) => state.builder.typographies);
  const [style, setStyle] = useState<React.CSSProperties>();
  const colors = useAppSelector((state) => state.builder.colors);
  const [hoverStyle, setHoverStyle] = useState<any>();

  const { toggleCart, cartItems, locationProducts, orderDeliveryMethod } =
    useShoppingCart();

  const totalSum = cartItems.reduce((total, cartItem) => {
    const item = locationProducts.find((i) => i.id === cartItem.id);
    return total + (item?.price_total || 0) * cartItem.quantity;
  }, 0);

  const totalDiscount = cartItems.reduce((total, cartItem) => {
    const item = locationProducts.find((i) => i.id === cartItem.id);

    const originalPrice = item?.price_total || 0;

    const discountedPrice = item?.price_with_discounts || 0;
    const discountAmount =
      (originalPrice - Number(discountedPrice)) * cartItem.quantity;

    return total + discountAmount;
  }, 0);

  const deliveryPrice =
    orderDeliveryMethod && orderDeliveryMethod.price && cartItems.length > 0
      ? Number(orderDeliveryMethod.price)
      : 0;

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );
    if (compTypography) {
      setStyle({
        display: "flex",
        alignItems: "center",

        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
        transition:
          compTypography.transition_duration +
          " color " +
          compTypography.transition_effect,
      });
      setHoverStyle({
        "&:hover, &:hover > svg, &:hover > div": {
          color:
            colors?.find(
              (x: any) => x.id === compTypography.link_hover_color_id
            )?.color + " !important",
        },
      });
    }
  }, [typographies, attributes]);

  return (
    <div
      id={id.toString()}
      data-component-type="cart"
      className={
        (attributes.classes ?? "") +
        " builder-component builder-component__cart" +
        (editorId === id ? " builder-component--active" : "")
      }
      style={{
        cursor: "pointer",
      }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
      <Div
        style={{ display: "flex", alignItems: "center" }}
        data-style={{ ...style, ...attributes.styles, ...hoverStyle }}
        onClick={() => toggleCart()}
      >
        <ShoppingCartIcon
          style={{
            fontSize: "21px",
            color: attributes.styles.borderTopColor,
          }}
        />

        <div
          style={{
            fontSize: "14px",
            paddingLeft: "3px",
            paddingRight: "3px",
            color: attributes.styles.borderTopColor,
            fontWeight: "600",
          }}
          contentEditable={editorId == id}
          suppressContentEditableWarning={true}
          onMouseUp={(e) => setMouseUp(e)}
          onKeyDown={(e) => setKeyDown(e)}
          onMouseOver={(e) => (e.currentTarget.style.color = "#fff")}
          onMouseOut={(e) =>
            (e.currentTarget.style.color = attributes.styles.borderTopColor)
          }
        >
          {/* {pf(
            cartItems.reduce((total, cartItem) => {
              const item = locationProducts.find((i) => i.id === cartItem.id);
              return total + (item?.price_total || 0) * cartItem.quantity;
            }, 0)
          )} */}
          {pf(
            totalSum - totalDiscount + (orderDeliveryMethod ? deliveryPrice : 0)
          )}{" "}
          €
        </div>
      </Div>
    </div>
  );
}
