import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./LanguageComponent.css";
import styled from "@emotion/styled";
import { LanguageComponent as LanguageComponent_ } from "./interfaces/base";
import { getPageLocaleUrl } from "./utilities/helpers";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function LanguageComponent({
  languageAttributes,
  attributes,
  id,
  childElements,
  childWebpageComponentRelations,
}: any) {
  const selectedWebpage = useAppSelector((state) => state.builder.pages.active);
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  ) as LanguageComponent_;
  const typographies = useAppSelector((state) => state.builder.typographies);
  const [style, setStyle] = useState<React.CSSProperties>();
  const [hoverStyle, setHoverStyle] = useState<any>();
  const colors = useAppSelector((state) => state.builder.colors);
  const webpages = useAppSelector((state) => state.builder.pages.list);
  const selectedWebpageData = useAppSelector((state) =>
    state.builder.pages.list.find((x) => x.id === selectedWebpage)
  );
  const languages = useAppSelector((state) => state.builder.languageSettings);

  const allLanguages = languages.activeLanguages;

  const sortedLanguages = languages.activeLanguages.filter(
    (x) => x.language.id === selectedWebpageData?.language?.language_id
  );

  const otherLanguages = languages.activeLanguages.filter(
    (x) => x.language.shortname !== selectedWebpageData?.language?.locale
  );

  const translationWebpageLanguages = otherLanguages
    .filter((x) =>
      selectedWebpageData?.translation_webpages?.some(
        (y) => y.language_id === x.id
      )
    )
    .filter((x) => {
      if (x.visitor_state === 1) {
        return true;
      }
    });

  sortedLanguages.push(...translationWebpageLanguages);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const params = useParams();
  let navigate = useNavigate();
  const pathname = useLocation();

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === languageAttributes.typography_id
    );
    if (compTypography) {
      setStyle({
        backgroundColor: attributes.styles.backgroundColor ?? "#fff",
        width: attributes.styles.width ?? "auto",

        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        fontStyle: compTypography.font_style,
        fontWeight: compTypography.font_weight,
        letterSpacing: compTypography.letter_spacing,
        lineHeight: attributes.styles.lineHeight ?? compTypography.line_height,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
        transition:
          compTypography.transition_duration +
          " color " +
          compTypography.transition_effect,
      });
      setHoverStyle({
        "&:hover": {
          color:
            colors?.find(
              (x: any) => x.id === compTypography.link_hover_color_id
            )?.color + " !important",
        },
      });
    }
  }, [typographies, attributes, languageAttributes]);

  function handleLanguageSelect(lang) {
    const targetTranslationWebpage =
      selectedWebpageData?.translation_webpages?.find(
        (x) => x.language_id === lang.id
      );

    if (targetTranslationWebpage) {
      const targetWebpage = webpages.find(
        (webpage) => webpage.id === targetTranslationWebpage.target_webpage_id
      );
      if (targetWebpage) {
        var to = getPageLocaleUrl(targetWebpage, allLanguages);
        if (to.substring(0, 1) !== "/") {
          to = "/" + to;
        }
        for (const key in params) {
          to = to.replace(`:${key}`, `${params[key]}`);
        }

        if (pathname.search.includes("?category=")) {
          to = to + pathname.search;
        }

        navigate(to);
      }
    }
  }

  const imageSizes = [
    {
      value: "small",
      width: "20px",
      height: "12px",
    },

    {
      value: "medium",
      width: "30px",
      height: "18px",
    },

    {
      value: "large",
      width: "45px",
      height: "24px",
    },
  ];

  return (
    <Div
      id={id.toString()}
      data-component-type="language-select"
      className={
        (attributes.classes ?? "") +
        " builder-component builder-component__language_select" +
        (editorId === id ? " builder-component--active" : "")
      }
      style={{
        ...attributes.styles,
      }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        {languageAttributes.dropdown === "dropdown" && (
          <div>
            <div
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
              className="language-dropdown-trigger"
              style={{
                color:
                  component.attributes?.styles?.color || style?.color || "#000",
              }}
            >
              <span
                style={{
                  ...style,
                  ...attributes.styles,
                  ...hoverStyle,
                  color:
                    component.attributes?.styles?.color ||
                    style?.color ||
                    "#000",
                  fontSize: languageAttributes.size,
                }}
              >
                {languageAttributes.type === "locale" &&
                  sortedLanguages[0]?.language.shortname}
              </span>

              <span
                style={{
                  ...style,
                  ...attributes.styles,
                  ...hoverStyle,
                  color:
                    component.attributes?.styles?.color ||
                    style?.color ||
                    "#000",
                  fontSize: languageAttributes.size,
                }}
              >
                {languageAttributes.type === "name" &&
                  sortedLanguages[0]?.language.name}
              </span>

              {languageAttributes.type === "image" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    src={sortedLanguages[0].language.icon_url}
                    style={{
                      width: languageAttributes.size,
                      objectFit: "cover",
                      height: languageAttributes.size
                        ? imageSizes.find(
                            (x) => x.value === languageAttributes.size
                          )?.height
                        : "12px",
                    }}
                    width={
                      languageAttributes.size !== "auto" &&
                      languageAttributes.size !== undefined &&
                      languageAttributes.size !== "px" &&
                      languageAttributes.size !== "rem" &&
                      languageAttributes.size !== ""
                        ? languageAttributes.size
                        : "24px"
                    }
                    height={"100%"}
                    className={""}
                  ></img>

                  {/* {sortedLanguages.map((lang) => {
                    return (
                      <img
                        src={lang.language.icon_url}
                        style={{
                          width: languageAttributes.size,
                          objectFit: "cover",
                          height: languageAttributes.size
                            ? imageSizes.find(
                                (x) => x.value === languageAttributes.size
                              )?.height
                            : "12px",
                        }}
                        width={
                          languageAttributes.size !== "auto" &&
                          languageAttributes.size !== undefined &&
                          languageAttributes.size !== "px" &&
                          languageAttributes.size !== "rem" &&
                          languageAttributes.size !== ""
                            ? languageAttributes.size
                            : "24px"
                        }
                        height={"100%"}
                        className={""}
                      ></img>
                    );
                  })} */}
                </div>
              )}

              <ArrowDropDownIcon
                style={{
                  fontSize: languageAttributes.size,
                }}
              />
            </div>

            {dropdownOpen && (
              <div
                className="language-dropdown"
                style={{
                  position: "absolute",
                  padding:
                    languageAttributes.type === "image" ? "10px" : "5px 10px",
                }}
              >
                {languageAttributes.type === "locale" && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {sortedLanguages?.slice(1).map((lang) => {
                      return (
                        <span
                          onClick={() => handleLanguageSelect(lang)}
                          key={lang.id}
                          className=""
                          style={{
                            fontWeight: "400",
                            textTransform: "uppercase",
                            color: "#333",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        >
                          {lang.language.shortname}
                        </span>
                      );
                    })}
                  </div>
                )}
                {languageAttributes.type === "name" && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {sortedLanguages?.slice(1).map((lang) => {
                      return (
                        <span
                          onClick={() => handleLanguageSelect(lang)}
                          key={lang.id}
                          className=""
                          style={{
                            fontWeight: "400",
                            textTransform: "uppercase",
                            color: "#333",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          {lang.language.name}
                        </span>
                      );
                    })}
                  </div>
                )}

                {languageAttributes.type === "image" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {sortedLanguages?.slice(1).map((lang) => {
                      return (
                        <img
                          onClick={() => handleLanguageSelect(lang)}
                          src={lang.language.icon_url}
                          style={{
                            objectFit: "cover",

                            cursor: "pointer",
                            height: "100%",
                            margin: "0",
                            padding: "0",
                          }}
                          width={"20px"}
                          className={""}
                        ></img>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {languageAttributes.type === "locale" &&
            languageAttributes.dropdown === "none" &&
            sortedLanguages?.map((lang) => {
              return (
                <div
                  onClick={() => handleLanguageSelect(lang)}
                  key={lang.id}
                  className=""
                  style={{
                    ...style,
                    ...attributes.styles,
                    ...hoverStyle,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    color:
                      component.attributes?.styles?.color ||
                      style?.color ||
                      "#000",
                    fontSize: languageAttributes.size,
                    marginTop: "4px",
                  }}
                >
                  {lang.language.shortname}
                </div>
              );
            })}
          {languageAttributes.type === "name" &&
            languageAttributes.dropdown === "none" &&
            sortedLanguages?.map((lang) => {
              return (
                <div
                  onClick={() => handleLanguageSelect(lang)}
                  key={lang.id}
                  className=""
                  style={{
                    ...style,
                    ...attributes.styles,
                    ...hoverStyle,

                    textTransform: "uppercase",
                    color:
                      component.attributes?.styles?.color ||
                      style?.color ||
                      "#000",
                    fontSize: languageAttributes.size,
                    marginTop: "4px",
                  }}
                >
                  {lang.language.name}
                </div>
              );
            })}
          {languageAttributes.type === "image" &&
            languageAttributes.dropdown === "none" &&
            sortedLanguages?.map((lang) => {
              return (
                <div
                  key={lang.id}
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: languageAttributes.size,
                  }}
                  onClick={() => {
                    handleLanguageSelect(lang);
                  }}
                >
                  <img
                    src={lang.language.icon_url}
                    style={{
                      width: attributes.size,

                      objectFit: "cover",
                      height: languageAttributes.size
                        ? imageSizes.find(
                            (x) => x.value === languageAttributes.size
                          )?.height
                        : "12px",
                    }}
                    width={
                      languageAttributes.size !== "auto" &&
                      languageAttributes.size !== undefined &&
                      languageAttributes.size !== "px"
                        ? languageAttributes.size
                        : "24px"
                    }
                    height={"100%"}
                    className={""}
                  ></img>
                </div>
              );
            })}
        </div>
      </div>
    </Div>
  );
}
